@import '@src/@core/scss/base/bootstrap-extended/include';
@import '@src/@core/scss/base/components/include';

.card-body {
  .media {
    padding: 1.25rem;
    width: 100%;
    margin-top: 0;
    align-items: flex-end;
    .media-left {
      margin-right: 1rem;
    }
    .media-right {
      margin-left: 1rem;
    }
    .media-body {
      label {
        padding: 8px 19px;
      }
    }
  }
  .form-group {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $green;
      background-color: $green;
    }
    .flatpickr-input {
      background-color: inherit;
    }
  }
  a.media {
    color: $gray-600 !important;
  }
  .btn-outline-orange {
    border: solid 1px #FF4201;
    color: #FF4201;
  }
}

.media-bordered {
  .media:not(:first-child) {
    border-top: 1px solid $border-color;
  }
}
